import { AddonName, Plan, PlanAddon } from '../../types'

export function planFixture({
  id = 1,
  guid = undefined,
  price = '$1.00',
  currencyCode = 'USD',
  pages = 100,
  isDefault = undefined,
  overageBlockPrice = '$1.00',
  overageBlockSize = 10,
  program = 'i_ink',
  waiver = 100,
  rollover = 100,
  frequency = 'light',
  addons = [],
  freeMonths = 3,
  yearlyPlan = false,
  priceCents = 100
} = {}): Plan {
  return {
    id,
    guid: guid || `plan-guid-${id}`,
    price: price || `$${id}.00`,
    currencyCode,
    pages: pages || id * 100,
    isDefault,
    overageBlockPrice,
    overageBlockSize,
    program,
    waiver: waiver || id * 100,
    rollover: rollover || id * 100,
    frequency,
    addons,
    freeMonths,
    yearlyPlan,
    priceCents
  }
}

export function paperAddonFixture({
  name = AddonName.INSTANT_PAPER,
  optedIn = false,
  basePrice = '$1.00',
  priceWithPlan = '$2.00',
  freeMonths = 1,
  trialEndDate = '08/12/4095',
  overageBlockPrice = '$11',
  overageBlockPriceWithPlan = '$12',
  overageBlockRange = 10,
  rollover = 10,
  lowestAdditionalSheetsPrice = '$0.50',
  lowestAdditionalSheetsQty = 10,
  highestAdditionalSheetsQty = 60
} = {}): PlanAddon {
  return {
    name,
    optedIn,
    basePrice,
    priceWithPlan,
    freeMonths,
    trialEndDate,
    overageBlockPrice,
    overageBlockPriceWithPlan,
    overageBlockRange,
    rollover,
    lowestAdditionalSheetsPrice,
    lowestAdditionalSheetsQty,
    highestAdditionalSheetsQty
  }
}

export function inkPlanFixture(props) {
  return planFixture({
    program: 'i_ink',
    waiver: 700,
    rollover: 3 * (props.pages || 10),
    addons: [],
    ...props
  })
}

export function inkPlansFixture(hasAddons = true) {
  return [
    ['$0.99', 10, 'light', 10],
    ['$3.99', 50, 'occasional', 10],
    ['$5.99', 100, 'moderate', 10],
    ['$11.99', 300, 'frequent', 10],
    ['$24.99', 700, 'microbiz', 15]
  ].map(([price, pages, frequency, overageBlockSize], index) =>
    inkPlanFixture({
      id: 100 + index,
      price,
      pages,
      frequency,
      overageBlockSize,
      isDefault: pages === 100,
      addons: hasAddons ? [paperAddonFixture()] : []
    })
  )
}

export function tonerPlanFixture(props) {
  return planFixture({
    program: 'i_toner',
    waiver: 1500,
    rollover: 2 * (props.pages || 50),
    ...props
  })
}

export function tonerPlansFixture() {
  return [
    ['$1.99', 50, 'light', 10],
    ['$3.99', 100, 'occasional', 10],
    ['$7.99', 200, 'moderate', 15],
    ['$13.99', 400, 'frequent', 20],
    ['$19.99', 800, 'microbiz', 50]
  ].map(([price, pages, frequency, overageBlockSize], index) =>
    tonerPlanFixture({
      id: 200 + index,
      price,
      pages,
      frequency,
      overageBlockSize,
      isDefault: pages === 50
    })
  )
}

export function smbTonerPlanFixture(props) {
  return planFixture({
    program: 'i_smb_toner',
    waiver: 3000,
    rollover: 2 * (props.pages || 200),
    ...props
  })
}

export function smbTonerPlansFixture() {
  return [
    ['$7.99', 200, 'light', 10],
    ['$13.99', 400, 'moderate', 15],
    ['$19.99', 800, 'medium', 20],
    ['$25.99', 1500, 'high', 50],
    ['$39.99', 3000, 'heavy', 60]
  ].map(([price, pages, frequency, overageBlockSize], index) =>
    smbTonerPlanFixture({
      id: 300 + index,
      price,
      pages,
      frequency,
      overageBlockSize,
      isDefault: pages === 800
    })
  )
}

export function smbColorTonerPlansFixture() {
  return [
    ['$24.99', 200, 'light', 5],
    ['$39.99', 400, 'moderate', 8],
    ['$59.99', 800, 'medium', 10],
    ['$79.99', 1500, 'high', 15],
    ['$119.99', 3000, 'heavy', 20]
  ].map(([price, pages, frequency, overageBlockSize], index) =>
    smbTonerPlanFixture({
      id: 400 + index,
      price,
      pages,
      frequency,
      overageBlockSize,
      isDefault: pages === 800,
      program: 'i_smb_toner_color'
    })
  )
}
