import { useCallback } from 'react'
import { getQueryParam } from '../lib/urlHelpers'
import { useLocation } from 'react-router-dom'

export const useLocaleParam = () => {
  const location = useLocation()
  const isLocaleSetParam = getQueryParam(location.search, 'isLocaleSet')

  const defineLocaleSetParam = useCallback(() => {
    if (isLocaleSetParam !== 'true') {
      const url = new URL(window.location.href)
      url.searchParams.set('isLocaleSet', 'true')
      window.history.replaceState({}, '', url.toString())
    }
  }, [isLocaleSetParam])

  const checkLocaleSet = () => {
    const isLocaleSet = localStorage.getItem('isLocaleSet')
    if (isLocaleSet === 'true') {
      defineLocaleSetParam()
      localStorage.removeItem('isLocaleSet')
    }
  }

  return { checkLocaleSet, defineLocaleSetParam }
}
