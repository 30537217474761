import React, { FC } from 'react'
import styled from 'styled-components'
import tokens from '@veneer/tokens'

type SkeletonTextProps = {
  marginTop?: number
  marginBottom?: number
  width?: number | string
  height?: number
  className?: string
  backgroundColor?: string
  large?: boolean
}

const StyledSkeletonText = styled.div<SkeletonTextProps>`
  max-width: ${({ large }) => (large ? `unset` : `480px`)};
  width: ${({ width }) =>
    width ? (typeof width === 'number' ? `${width}px` : width) : `100%`};
  height: ${({ height }) => height || 24}px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || tokens.color.gray3};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom !== undefined ? marginBottom : 32}px;
  margin-top: ${({ marginTop }) => (marginTop !== undefined ? marginTop : 0)}px;
`

export const SkeletonText: FC<SkeletonTextProps> = (props) => (
  <StyledSkeletonText {...props} />
)
