import { requestStateData } from './requestStateData'
import { SignupState } from '../types'

export const createSubscription = () =>
  requestStateData(
    'subscription',
    ({
      signupSession: { instantInkService },
      partialSubscription,
      localization
    }: SignupState) =>
      instantInkService.createSubscription(
        partialSubscription?.data?.id,
        `${localization.language}_${localization.country.toUpperCase()}`
      )
  )

export const convertFlipSubscription = () =>
  requestStateData(
    'subscription',
    ({
      signupSession: { instantInkService, accountIdentifier },
      partialSubscription,
      localization
    }: SignupState) =>
      instantInkService.convertFlipSubscription(
        partialSubscription?.data?.id,
        accountIdentifier,
        `${localization.language}_${localization.country.toUpperCase()}`
      )
  )

export const replacePrinter = () =>
  requestStateData(
    'subscription',
    ({
      signupSession: { instantInkService },
      partialSubscription
    }: SignupState) =>
      instantInkService.replacePrinter(partialSubscription?.data?.id)
  )
