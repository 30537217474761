import React from 'react'
import styled from 'styled-components'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 16px;
  width: 100%;
  min-height: 320px;

  > div div {
    width: 60px;
    height: 60px;
  }
`

export const LoadingPage = () => (
  <Container data-testid="progress-indicator-container">
    <ProgressIndicator />
  </Container>
)
