import { requestStateData } from './requestStateData'
import { useStateData } from './useStateData'
import { SignupState } from '../types'

export const fetchPrinterOffer = () =>
  requestStateData(
    'printerOffer',
    ({
      signupSession: {
        instantInkService,
        printerSku,
        printerSerialNumber,
        printerBizModel,
        supplyTypes
      },
      localization,
      isLoggedIn,
      partialSubscription
    }: SignupState) => {
      return instantInkService.fetchPrinterOffer(
        printerSerialNumber || '',
        printerSku || '',
        printerBizModel || '',
        supplyTypes || '',
        true,
        localization.country,
        'oobe',
        isLoggedIn ? partialSubscription?.data?.id : undefined
      )
    }
  )

export const usePrinterOffer = () =>
  useStateData('printerOffer', fetchPrinterOffer, () => true)
