import { useMemo } from 'react'
import {
  useUserLocale,
  usePartialSubscription,
  useUserAccount,
  useFlowContext,
  useSignupState
} from '../hooks'
import { SupportedLocales } from '../assets/locale'

export const useCountrySupport = () => {
  const userLocale = useUserLocale()
  const { localization } = useSignupState()
  const { userHasOtherSubscriptions } = usePartialSubscription()?.data || {}
  const { regionId } = useUserAccount().data || {}
  const { isHpIdValuePropPage } = useFlowContext().getOptions()
  const clientLocale = `${
    localization.language
  }_${localization.country.toUpperCase()}`

  const isStratusCountryUnsupported = useMemo(() => {
    return (
      regionId?.toUpperCase() !== userLocale.country?.toUpperCase() &&
      !userHasOtherSubscriptions &&
      !isHpIdValuePropPage
    )
  }, [regionId, userLocale, userHasOtherSubscriptions, isHpIdValuePropPage])

  const isCountryNotSupported = useMemo(() => {
    return (
      !SupportedLocales.includes(clientLocale) || isStratusCountryUnsupported
    )
  }, [clientLocale, isStratusCountryUnsupported])

  const isSelectedCountryDiffAsAccount = useMemo(() => {
    return localization.country.toUpperCase() !== userLocale.country
  }, [localization, userLocale])

  return {
    isStratusCountryUnsupported,
    isCountryNotSupported,
    isSelectedCountryDiffAsAccount
  }
}
